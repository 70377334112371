import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("Network Profiles")]),_c(VSpacer),_c(VBtn,{staticClass:"mx-2",attrs:{"fab":"","depressed":"","small":"","dark":"","color":"primary","to":"/network-profiles/new"}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1),_c(VBtn,{staticClass:"mr-2",attrs:{"fab":"","depressed":"","small":"","loading":_vm.loading},on:{"click":_vm.onRefresh}},[_c(VIcon,[_vm._v("mdi-refresh")])],1)],1),_c('crud-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tableItems,"items-per-page":_vm.config.defaultPageSize,"enable-search":true},on:{"edit":_vm.onEditItem,"delete":_vm.onDeleteItem}})],1),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }