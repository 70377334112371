
import {Component, Vue} from "vue-property-decorator";
import {DataTableHeader} from "vuetify";
import {namespace} from "vuex-class";
import {NetworkProfile} from "@/domain/network-profile";
import CrudDataTable from "@/components/CrudDataTable.vue";
import Config from "@/domain/config";

const networkProfilesModule = namespace('networkProfiles');

@Component({
  components: {CrudDataTable},
  data: () => ({
    config: {
      defaultPageSize: 15,
    } as Partial<Config>,
  })
})
export default class NetworkProfiles extends Vue {

  @networkProfilesModule.State
  readonly loading!: boolean;

  @networkProfilesModule.State
  readonly items!: NetworkProfile[];

  public headers: DataTableHeader[] = [
    {text: 'ID', value: 'id'},
    {text: 'Name', value: 'name'},
    {text: 'IP ranges', value: 'ipRanges'},
  ];

  get tableItems() {
    return this.items.map(
      (item: NetworkProfile) => {
        return {
          ...item,
          ipRanges: item.ipRanges.map((ipRange) => ipRange.ipCidrRange).join(', ')
        }
      }
    );
  }

  mounted() {
    this.$store.dispatch('specific/getConfig').then(
        value => this.$data.config = value
    );
    this.$store.dispatch("networkProfiles/fetchAll");
  }

  onRefresh() {
    this.$store.dispatch("networkProfiles/fetchAll");
  }

  onEditItem(item: NetworkProfile) {
    if (!item.id) {
      return;
    }
    this.$router.push({
      name: 'network-profile-edit',
      params: {
        id: item.id.toString()
      }
    });
  }

  onDeleteItem(item: NetworkProfile) {
    if (item.id === 1) {
      window.alert('You cannot delete the default network profile');
      return;
    }
    if (window.confirm('Are you sure you want to delete network profile ' + item.name + '?')) {
      this.$store.dispatch("networkProfiles/deleteOne", item.id)
          .then(() => {
            this.$store.dispatch("networkProfiles/fetchAll");
          })
          .catch((error) => {
            console.error('Failed deleting network profile', error);
            window.alert('An error occurred while deleting the network profile:\n\n' + (error.message || JSON.stringify(error)));
          });
    }
  }

}
