

import {Component, Prop, Vue} from "vue-property-decorator";
import {DataTableHeader} from "vuetify";
import {ref} from "vue";

const search = ref('');

@Component({
  data() {
    return {
      search,
    }
  }
})
export default class CrudDataTable extends Vue {


  @Prop({type: Boolean, default: false})
  readonly loading!: boolean;

  @Prop({type: Boolean, default: false})
  readonly enableSearch!: boolean;

  @Prop({type: Array, required: true})
  readonly headers!: DataTableHeader[];

  @Prop({type: Array, required: true})
  readonly items!: any[];

  @Prop({type: Number, default: 15})
  readonly itemsPerPage!: number;

  get computedHeaders(): DataTableHeader[] {
    return [
      ...this.headers,
      {
        text: 'Action', value: 'action', sortable: false, align: 'center'
      }
    ];
  }
}
