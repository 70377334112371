import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.enableSearch)?_c(VTextField,{attrs:{"label":"Search","prepend-inner-icon":"mdi-magnify","single-line":"","variant":"outlined","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.computedHeaders,"items":_vm.items,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"item-class":function(item) {
            if (item.readOnly) {
              return 'read-only'
            }
            else {
              return null
            }
          },"search":_vm.search},on:{"click:row":function($event){return _vm.$emit('edit', $event)}},scopedSlots:_vm._u([{key:"item.id",fn:function({ item }){return [(!item.readOnly)?_c('span',[_vm._v(_vm._s(item.id))]):_vm._e()]}},{key:"item.action",fn:function({ item }){return [(!item.readOnly)?_c(VIcon,{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('delete', item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }